
import { defineComponent } from "vue";
import PlanJournal from "@/components/journals/PlanJournal/PlanJournal.vue";

export default defineComponent({
  components: { PlanJournal },
  props: {},
  methods: {},
  data() {
    return {};
  },
});
